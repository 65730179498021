import React from "react";

export function GoogleAnalytics() {
  return (
    <div>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-118369289-1"
      />
      <script async src="/static/google-analytics-tracking.js" />
    </div>
  );
}
