import React from "react";
import "./Label.scss";

export function Label({ text, href, newTab = false }) {
  if (href) {
    let props = {
      className: "label label--link",
      title: text,
      href
    };

    if (newTab) {
      props = { ...props, target: "_blank" };
    }

    return <a {...props}>{text}</a>;
  }

  return <span className="label">{text}</span>;
}
