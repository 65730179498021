import React from "react";

export function Project({ name, description, link, languages = [] }) {
  return (
    <a
      className="project"
      href={link}
      title={name}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className="project-name">{name}</div>
      <div className="project-description">{description}</div>
      <div className="project-languages">
        {languages.map(lang => (
          <Language key={lang} lang={lang} />
        ))}
      </div>
    </a>
  );
}

function Language({ lang }) {
  return <div className={`project-language ${lang}`}>{lang}</div>;
}
