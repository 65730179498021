import React from "react";
import { Project } from "./Project";
import "./Projects.scss";

export function Projects({ items = [] }) {
  return (
    <div className="projects">
      {items.map((item, index) => (
        <Project key={index} {...item} />
      ))}
    </div>
  );
}
