import React from "react";

export function Book(props) {
  const { link, name } = props;

  if (link) {
    return (
      <a
        className="no-decoration"
        href={link}
        rel="noopener noreferrer"
        target="_blank"
        title={`Levente Balogh's favourite quotes from ${name}`}
      >
        <BookInner {...props} />
      </a>
    );
  }

  return <BookInner {...props} />;
}

function BookInner({ name, author, cover, link }) {
  return (
    <div className="book">
      <div
        className="book-cover"
        style={{ backgroundImage: `url('${cover}')` }}
      />
      <div className="book-name">{name}</div>
      <div className="book-author">{author}</div>
      {link && <span className="book-quotes">Read quotes</span>}
    </div>
  );
}
