import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Introduction } from "../Introduction";
import { Projects } from "../Projects";
import { Books } from "../Books";
import { Blogposts } from "../Blogposts";
import { Container, Section } from "../Layout";
import { projects, books, blogposts } from "../../content";

export const PageHome = () => (
  <Container>
    <Header />
    <Introduction />

    <Section title="Projects">
      <Projects items={projects} />
    </Section>

    <Section title="Books I Liked">
      <Books items={books} />
    </Section>

    <Section title="Blogposts">
      <Blogposts items={blogposts} />
    </Section>

    <Footer />
  </Container>
);
