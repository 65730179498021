import React from "react";

export function Blogpost(props) {
  const { link, title } = props;

  return (
    <a
      className="no-decoration"
      href={link}
      title={title}
      rel="noopener noreferrer"
      target="_blank"
    >
      <BlogpostInner {...props} />
    </a>
  );
}

function BlogpostInner({ title, date, excerpt }) {
  return (
    <div className="blogpost">
      <div className="blogpost-title">
        {title}
        <span className="blogpost-date">&middot; {date}</span>
      </div>
      <div className="blogpost-excerpt">{excerpt}</div>
      <span className="blogpost-link">Read more</span>
    </div>
  );
}
