export const projects = [
  {
    name: "React-Apollo Template",
    description:
      "A starter kit for server-side rendered React - Apollo applications.",
    link: "https://github.com/leventebalogh/react-apollo-template",
    languages: ["javascript", "CSS", "SASS"]
  },
  {
    name: "React UI",
    description: "A UI kit for frequently used website building blocks",
    link: "https://github.com/leventebalogh/react-ui",
    languages: ["javascript", "CSS", "tailwind"]
  },
  {
    name: "Heimdall",
    description:
      "A lightweight password manager written in Javascript with Electron.",
    link: "https://github.com/leventebalogh/heimdall",
    languages: ["javascript"]
  },
  {
    name: "Lightningbox",
    description: "A fast and super-small image viewer written in Javascript.",
    link: "https://github.com/leventebalogh/lightningbox",
    languages: ["javascript"]
  },
  {
    name: "Deployer",
    description:
      "Deploy docker containers easily by Github repo name and branch/tag.",
    link: "https://github.com/leventebalogh/deployer",
    languages: ["javascript"]
  },
  {
    name: "Node Simple DI",
    description: "A simple configuration-file-based DI system for Node.js",
    link: "https://github.com/leventebalogh/node-simple-di",
    languages: ["javascript"]
  }
];
