import React from "react";
import { GoogleAnalytics } from "../GoogleAnalytics";
import "./Footer.scss";

export function Footer() {
  return (
    <div className="footer">
      <GoogleAnalytics />
    </div>
  );
}
