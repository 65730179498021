import React from "react";
import "./Introduction.scss";

export function Introduction() {
  return (
    <div className="introduction">
      I think one of the most important thing in our job is{" "}
      <strong>curiosity</strong>.<br />
      If you <strong>stay curious</strong> you can truly understand how things
      work and only by that can you become a master craftsman.
      <br />
      <br />
      And this is one of the main reasons why I love my job so much: it's like
      an adventurous video game, where there is always a next level.
    </div>
  );
}
