import React from "react";
import { Label } from "../Label";
import "./Header.scss";

export function Header() {
  return (
    <div className="header">
      <h1>
        Levente Balogh
        <span className="header-cursor" />
      </h1>
      <div className="header-labels">
        <Label text="software-developer" />
        <Label text="github" newTab href="https://github.com/leventebalogh" />
        <Label text="medium" newTab href="https://medium.com/@levente.balogh" />
        <Label text="email" href="mailto:balogh.levente.hu@gmail.com" />
      </div>
    </div>
  );
}
