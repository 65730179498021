export const books = [
  {
    name: "Clean Code",
    author: "Robert Cecil Martin",
    cover: "/static/books/clean-code.jpg",
    link:
      "https://github.com/leventebalogh/books.programming/blob/master/books/Clean-Code/content.md"
  },
  {
    name: "Clean Coder",
    author: "Robert Cecil Martin",
    cover: "/static/books/clean-coder.jpg",
    link:
      "https://github.com/leventebalogh/books.programming/blob/master/books/Clean-Coder/content.md"
  },
  {
    name: "Your Code As A Crime Scene",
    author: "Adam Tornhill",
    cover: "/static/books/code-as-a-crime-scene.jpg",
    link:
      "https://github.com/leventebalogh/books.programming/blob/master/books/Your-Code-As-A-Crime-Scene/content.md"
  },
  {
    name: "Code",
    author: "Charles Petzold",
    cover: "/static/books/code.jpg",
    link:
      "https://github.com/leventebalogh/books.programming/blob/master/books/Code/content.md"
  },
  {
    name: "Continous Delivery",
    author: "Jez Humble",
    cover: "/static/books/continous-delivery.jpg",
    link:
      "https://github.com/leventebalogh/books.programming/blob/master/books/Continuous-Delivery/content.md"
  },
  {
    name: "Test Driven Development",
    author: "Kent Beck",
    cover: "/static/books/test-driven-development.jpg",
    link:
      "https://github.com/leventebalogh/books.programming/blob/master/books/Test-Driven-Development/content.md"
  },
  {
    name: "The Pragmatic Programmer",
    author: "Andrew Hunt",
    cover: "/static/books/pragmatic-programmer.jpg",
    link:
      "https://github.com/leventebalogh/books.programming/blob/master/books/The-Pragmatic-Programmer/content.md"
  },
  {
    name: "Release It!",
    author: "Michael T. Nygard",
    cover: "/static/books/release-it.jpg",
    link: ""
  },
  {
    name: "The Mythical Man-Month",
    author: "Frederick P. Brooks Jr.",
    cover: "/static/books/mythical-man-month.jpg",
    link: ""
  },
  {
    name: "Extreme Programming Explained",
    author: "Kent Beck",
    cover: "/static/books/extreme-programming-explained.jpg",
    link: ""
  }
];
