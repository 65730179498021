export const blogposts = [
  {
    title: "How to use redux-thunk with Next.js?",
    date: "Feb 4. (2018)",
    excerpt:
      "The first time I tried to use redux-thunk with Next.js it gave me some headache to make it work with the server-side rendering.",
    link:
      "https://medium.com/@levente.balogh/how-to-use-redux-thunk-with-next-js-5daf3fcd14fd"
  },
  {
    title: "Watching tests — Mocha & Babel & React",
    date: "May 26. (2017)",
    excerpt: "Mocha only watches test files by default if you are testing…",
    link:
      "https://medium.com/@levente.balogh/watching-tests-mocha-babel-react-2fafe395b96"
  },
  {
    title: "Handle non-deterministic tests in Intern.js",
    date: "Jul 31. (2016)",
    excerpt:
      "This short tutorial is about how to separate certain end-to-end or functional tests from the others, that are non-deterministic.",
    link:
      "https://medium.com/@levente.balogh/handle-non-deterministic-tests-in-intern-js-3e70c79d8f5f"
  },
  {
    title: "Deploy Meteor with Docker",
    date: "Sep 19. (2015)",
    excerpt:
      "I am using Meteor JS almost for a year now, had a few hobby projects, and I love it. However, when it comes to deploying, it was always a headache for me.",
    link:
      "https://medium.com/@levente.balogh/deploy-meteor-with-docker-4d251e7916fe"
  }
];
