import React from "react";
import { Book } from "./Book";
import "./Books.scss";

export function Books({ items = [] }) {
  return (
    <div className="books">
      {items.map((item, index) => (
        <Book key={index} {...item} />
      ))}
    </div>
  );
}
