import React from "react";
import { Blogpost } from "./Blogpost";
import "./Blogposts.scss";

export function Blogposts({ items = [] }) {
  return (
    <div className="blogposts">
      {items.map((item, index) => (
        <Blogpost key={index} {...item} />
      ))}
    </div>
  );
}
